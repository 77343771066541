export interface ICityType {
    code: string;
    state: string;
    es: string;
    en: string;
    active: boolean;
}

export function defaultICityType(): ICityType {
    return {
        code: '',
        state: '',
        es: '',
        en: '',
        active: false
    }
  }