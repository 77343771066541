import React from "react";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Helmet } from "react-helmet";

const Us = () => {

  const terminos = `

Somos **RadioLibreOnline**  un nuevo directorio de emisoras online AM/FM que podrás escuchar en cualquier dispositivo conectado a internet. Nunca te pediremos información personal, el acceso a nuestra página es totalmente libre.

Nuestro directorio está en constante actualización con emisoras emitidas en todo el mundo, si no encuentras tu emisora favorita háznoslo  saber enviados los datos correspondientes en el link  **Incluirturadio** .
\n\n
Si te gusta nuestro sitio, comparte nuestro link con tus amigos y familiares.`;

  const renderText = () => {
    const lines = terminos.split('\n');
    const renderedLines = lines.map((line, index) => {

      if (line.startsWith('**') && line.endsWith('**')) {

        const title = line.substring(2, line.length - 2);
        return <h2 key={index}><strong>{title}</strong></h2>;
      }
      const words = line.split(' ');
      const renderedWords = words.map((word, wordIndex) => {
        if (word.startsWith('**') && word.endsWith('**')) {
          const boldWord = word.substring(2, word.length - 2);
          return <strong key={wordIndex}>{boldWord}</strong>;
        }
        return word + ' ';
      });
      return <p key={index}>{renderedWords}</p>;
    });
    return renderedLines;
  };

  return (
    <div style={{paddingTop:80,  display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>RadioLibreOnline | Contáctenos</title>
        </Helmet>
        <Container component="main" style={{marginTop:8,marginBottom:2}}>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            Nosotros
            </Typography>
            <div style={{textAlign: 'justify'}}>
                RadioLibreOnline es un nuevo directorio de emisoras en línea AM/FM que puedes sintonizar en cualquier dispositivo con conexión a internet. Queremos enfatizar que nunca solicitaremos información personal, y el acceso a nuestra página es completamente gratuito.
                <br></br><br></br>
                Nuestro directorio se actualiza constantemente con emisoras de todo el mundo. Si no encuentras tu estación favorita, por favor avísanos enviando los datos correspondientes a través del enlace 'Incluir tu radio'.
                <br></br><br></br>
                Si disfrutas de nuestro sitio, te invitamos a compartir nuestro enlace con tus amigos y familiares.
            </div>
        </Container>
    </div>
  );
};

export default Us;