export interface ICountryType {
    code: number;
    es: string;
    en: string;
    iso2: string;
    iso3: string;
    flag: string;
    active: boolean;
}

export function defaultICountryType(): ICountryType {
    return {
        code: 0,
        es: '',
        en: '',
        iso2: '',
        iso3: '',
        flag: '',
        active: false
    }
  }