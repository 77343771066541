export interface IStateType {
    country: number;
    code: string;
    es: string;
    en: string;
    active: boolean;
}

export function defaultIStateType(): IStateType {
    return {
        code: '',
        country: 0,
        es: '',
        en: '',
        active: false
    }
  }