import CryptoJS from 'crypto-js';
import bcrypt from 'bcryptjs';

export const encryptPassword = (password:string, secretKey:string) => {

  var key = CryptoJS.enc.Hex.parse(secretKey);
  var iv = CryptoJS.enc.Hex.parse(secretKey);

  var encrypted = CryptoJS.AES.encrypt(password, key, {
      iv,
      padding: CryptoJS.pad.ZeroPadding,
  },);

  return encrypted.toString();
};

export const createBcryptHash = async (password:string) => {
  const saltRounds = 10;
  const salt = await bcrypt.genSalt(saltRounds);

  const hashedPassword = await bcrypt.hash(password, salt);

  return hashedPassword;
};
