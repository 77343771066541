import { Container } from "@mui/material";

const DashBoard = () => {
    return (
        <div style={{display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
            <Container component="main" maxWidth="xs">
            </Container>
        </div>
    );
}

export default DashBoard;