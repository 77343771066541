import React from 'react';

const Loader = (props:any) => {

    return (
        <div>
                <div id="loaderDocument"
                  style={{
                      display: props.isLoading ? 'block' : 'none',
                      width: "96px",
                      height: "96px",
                      position: "absolute",
                      zIndex: 2147483647,
                      left: "635px",
                      top: "187px"
                  }}>
                  <img alt='' style={{
                      width: '100%',
                      height: '100%',
                  }} id="ioip-loader-icon" src={"https://memexpoxtacdn.blob.core.windows.net/memex-poxta-core/loader.gif"}></img>
                </div>
                <div id="backDocument"
                  style={{
                      display: props.isLoading ? 'block' : 'none',
                      position: 'absolute',
                      width: window.document.body.offsetWidth + 'px',
                      height: window.document.body.scrollHeight + 'px',
                      left: '0px',
                      top: '0px',
                      background: 'rgba(255, 255, 255, 0.58)',
                      zIndex: 999999999
                  }}>
                </div>
        </div>
    );
  };

  export default Loader;
