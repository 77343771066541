import { useCallback, useState, Suspense} from "react";

import { Routes, Route } from "react-router-dom";
import { MoonLoader } from 'react-spinners'

import { ICountryType, defaultICountryType } from "../../interfaces/ICountryType";

import Page from "../Page"
import Footer from "../page/Footer";
import Loader from '../utils/Loader';
import Contact from "../page/Contact";
import Include from "../page/Include";
import Nosotros from "../page/Us";
import General from "../page/General";

import Reproductor from "../pruebas/reproductor";
import Login from "../admin/Login";
import ToolBar from "./ToolBar";
import DashBoard from "../admin/DashBoard";

const Router = () => {

  const [loading, setLoader] = useState(false);
  const [libraryStatus, setLibraryStatus] = useState(false);

  /*
  * Country
  */
  const [country, setCountry] = useState<ICountryType>(defaultICountryType());

  const onChangeCountry = useCallback((country:ICountryType) => {
    setLoader(true);

    if(country !== null)
    {
      setCountry(country);
      localStorage.setItem('radio_libre_country', JSON.stringify(country));
    }
    setLoader(false);
  }, [])

  return (
      <div>
        <Loader
          isLoading = {loading}
        ></Loader>
        <Suspense fallback={<div style={{display:'flow', justifyContent:'center', alignItems:'center'}}><MoonLoader color='black'/></div>}>
          <ToolBar libraryStatus={libraryStatus} setLibraryStatus={setLibraryStatus}/>
        </Suspense>
        <Routes>
           <Route path="/" element={<Page
                                      country={country}
                                      onChangeCountry={onChangeCountry}
                                      libraryStatus={libraryStatus}
                                      setLibraryStatus={setLibraryStatus}
                                     />
                                    } />
            <Route path="/contacto" element={<Contact/>}/>
            <Route path="/incluir_radio" element={<Include/>}/>
            <Route path="/general" element={<General/>}/>
            <Route path="/nosotros" element={<Nosotros/>}/>

            <Route path="/login-admin" element={<Login/>}/>
            <Route path="/menu-admin" element={<DashBoard/>}/>

            <Route path="/reproductor" element={<Reproductor/>}/>
        </Routes>
        <Footer/>
      </div>
  );
};

export default Router;