import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBaseQuery } from './baseApi';
import { ICountryType } from '../interfaces/ICountryType';


export const countryApi = createApi({
    reducerPath: 'countryApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (builder) => ({
        getAllAsync: builder.query<ICountryType[],void>({
            query: () => `v1/country`,
            keepUnusedDataFor: 86400,
            extraOptions:{ maxRetries: 3, backoff: 2 }
        }),
    })
});
