import "./styles/app.scss";

import { HashRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import Router from "./components/menu/Router";

function App() {

  return (
      <div style={{display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
      <CssBaseline />
        <HashRouter>
        <Router/>
        </HashRouter>
      </div>
  );
}

export default App;
