import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBaseQuery } from './baseApi';
import { IStateType } from '../interfaces/IStateType';


export const stateApi = createApi({
    reducerPath: 'stateApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (builder) => ({
        getActiveByCountry: builder.query<IStateType[], number>({
            query: (country) => `v1/state?country=${country}`,
            keepUnusedDataFor: 86400,
            extraOptions:{ maxRetries: 3, backoff: 2 }
        }),
    })
});
