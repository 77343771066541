import React from "react";
import {Helmet} from "react-helmet";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Contact = () => {

  return (
    <div style={{paddingTop:80, display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RadioLibreOnline | Contáctenos</title>
      </Helmet>
      <Container component="main" style={{marginTop:8,marginBottom:2}}>
        <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
          Contáctenos
        </Typography>
        <div style={{textAlign: 'justify'}}>
          <strong>¿DUDAS, INQUIETUDES O SUGERENCIAS?</strong><br></br><br></br>
          Si necesitas información, no dudes en ponerte en contacto con nosotros a través de la dirección de correo electrónico contacto@radiolibreonline.com.
        </div>
      </Container>
    </div>
  );
};

export default Contact;