import { Link } from 'react-router-dom';
import { Link as Linked } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { SocialIcon } from 'react-social-icons'
import Global from '../../appGlobals';

const Copyright = (props:any) => {
    return (
      <Typography variant="body2" style={{color: 'white'}} align="center" {...props}>
        {'Copyright © '}
        <Linked style={{color:'white'}} href="https://www.radiolibreonline.com/">
          RadioLibreOnline
        </Linked>{' '}
        {new Date().getFullYear()}
        {' - ' + Global.Version}
      </Typography>
    );
}

const Footer = () => {

  return (
        <Box
            component="footer"
            sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            color : '#FFF',
            backgroundColor: (theme) =>
                theme.palette.grey[800]
            }}
        >

        <Container maxWidth="md"
            component="footer"
            sx={{
            py: [2, 2],
            }}>
            <Grid item xs={6} sm={4}>
              <Typography variant="body1" style={{color: 'white', textAlign:'justify'}} gutterBottom>
              RadioLibreOnline es un nuevo directorio de emisoras AM/FM en línea que podrás sintonizar en cualquier dispositivo con acceso a internet
              </Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="space-evenly">
                    <Grid item xs={6} sm={3}>
                        <Typography variant="subtitle1" style={{color: 'white'}} gutterBottom>
                        RadioLibreOnline
                        </Typography>
                        <ul>
                            <li>
                            <Link to={'/contacto'} style={{color: 'white'}}>
                            Contáctenos
                            </Link>
                            </li>
                            <li>
                            <Link to={'/incluir_radio'} style={{color: 'white'}}>
                            Incluir tu radio
                            </Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography variant="subtitle1" style={{color: 'white'}} gutterBottom>
                        Legal
                        </Typography>
                        <ul>
                            <li>
                            <Link to={'/general'}  style={{color: 'white'}}>
                              Condiciones Generales
                            </Link>
                            </li>
                            <li>
                            <Link to={'/nosotros'} style={{color: 'white'}}>
                              Nosotros
                            </Link>
                            </li>
                        </ul>
                    </Grid>
            </Grid>
            <Typography variant="subtitle1" style={{color: 'white', textAlign:'center'}}>
              Síguenos en<SocialIcon network="instagram" bgColor="#424242"  url="https://www.instagram.com/radiolibreonline/?utm_source=qr&igshid=OGU0MmVlOWVjOQ%3D%3D" target='_blank' />
            </Typography>
            <Copyright style={{color: 'white'}} sx={{ mt: 1 }} />
            </Container>
        </Box>
  );
};

export default Footer;
