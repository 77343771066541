import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { IMenu } from '../../interfaces/IMenu';
import { useNavigate } from "react-router-dom";

const ToolBar = React.memo((props:IMenu) => {

  const navigate = useNavigate();
  const [location, setLocation] = React.useState(window.location.href);
  const [admin, setAdmin] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogout = () => {
    navigate("/");
  }

  const isAdminUrl = (url: string) => {

    if(location.includes('menu-admin'))
      return true;

    return false;
  }

  React.useEffect(() => {
    setLocation(window.location.href);
  },[window.location.href]);

    return (
      <AppBar style={{background:'#424242'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="http://www.radiolibreonline.com"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
            style={{color:'white'}}
          >
            RadioLibreOnline
          </Typography>
          { isAdminUrl(location) &&
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Usuarios</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Emisoras</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Logos</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Estados</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Regiones</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Ciudades</Typography>
                </MenuItem>
            </Menu>
          </Box>
          }
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="http://www.radiolibreonline.com"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
            style={{color:'white'}}
          >
            RadioLibreOnline
          </Typography>
          { isAdminUrl(location) &&
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Button
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Usuarios
                </Button>
                <Button
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Emisoras
                </Button>
                <Button
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Logos
                </Button>
                <Button
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Estados
                </Button>
                <Button
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Regiones
                </Button>
                <Button
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Ciudades
                </Button>
            </Box>
          }

          <Box style={{ marginLeft: "auto" }}>
          { !isAdminUrl(location) &&
            <MenuItem>
              <Typography onClick={() => props.setLibraryStatus(!props.libraryStatus)} textAlign="center" >Buscar Emisoras</Typography>
            </MenuItem>
          }
          { isAdminUrl(location) &&
            <Tooltip title="Opciones de Perfil">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Usuario" src="/images/avatar/user.png" />
              </IconButton>
            </Tooltip>
          }
          { isAdminUrl(location) &&
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Perfil</Typography>
                </MenuItem>
                <MenuItem onClick={() => onLogout()}>
                  <Typography textAlign="center">Cerrar Cesión</Typography>
                </MenuItem>
            </Menu>
          }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    );
});

export default ToolBar;