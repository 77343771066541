import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { countryApi } from './countryApi';
import { stateApi } from './stateApi';
import { stationApi } from './stationApi';

export const store = configureStore({

    reducer: {
        [ countryApi.reducerPath ]: countryApi.reducer,
        [ stateApi.reducerPath ]: stateApi.reducer,
        [ stationApi.reducerPath ]: stationApi.reducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
        countryApi.middleware,
        stateApi.middleware,
        stationApi.middleware
    ])
});

setupListeners(store.dispatch);