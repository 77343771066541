import React, { useCallback, useEffect, useState, lazy, Suspense, useRef } from "react";

import { MoonLoader } from 'react-spinners'
import ReactHlsPlayer from "react-hls-player/dist";

import { IStationType, defaultIStationType } from "../interfaces/IStationType";
import { ICountryType } from "../interfaces/ICountryType";
import { Typography } from "@mui/material";
import Global from "../appGlobals";

const Library = lazy(() => import('./principal/Library'))
const Station = lazy(() => import('./principal/Station'))
const Player = lazy(() => import('./principal/Player'))
const Country = lazy(() => import('./utils/Country'))
const TopStation = lazy(() => import('./utils/TopStation'))

const Page = (props:any) => {

  /*
  * Station
  */
  const [stations, setStations] = useState<IStationType[]>([]);
  const [currentStation, setCurrentStation] = useState<IStationType>(defaultIStationType());

  useEffect(() => {

    const info = localStorage.getItem("radio_libre_station_player");

    if (info !== void 0 && info !== null && info !== '' && currentStation?.code <= 0)
        setCurrentStation(JSON.parse(info));
    else
        setCurrentStation(currentStation);

  },[currentStation]);

  /*
  * Audio
  */
  const audioRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [stationInfo, setStationInfo] = useState({
    currentTime: 0,
    duration: 0,
    animationPercentage: 0,
  });

  //Eventos

  const onChangeCountry = useCallback(async (country:ICountryType) => {
    props.onChangeCountry(country);
  },[]);

  const timeUpdateHandler = (e:any) => {
    const currentTime = e.target.currentTime;
    const duration = e.target.currentTime;

    // Calculate Percentage
    const roundedCurrent = Math.round(currentTime);
    const roundedDuration = Math.round(duration);
    const animation = Math.round((roundedCurrent / roundedDuration) * 100);

    setStationInfo({
      ...stationInfo,
      currentTime,
      duration,
      animationPercentage: animation,
    });
  };

  const activeLibraryHandler = (nextPrev:any) => {

    const newStation = stations.map((station:IStationType) => {
      if (station.code === nextPrev.code) {
        return {
          ...station,
          active: true,
        };
      } else {
        return {
          ...station,
          active: false,
        };
      }
    });
    setStations(newStation);
  };

  const stationEndHandler = async () => {

    const currentIndex = stations.findIndex((station:IStationType) => station.code === currentStation.code);
    await setCurrentStation(stations[(currentIndex + 1) % stations.length]);
    activeLibraryHandler(stations[(currentIndex + 1) % stations.length]);

    if (isPlaying)
    {
      const audioPromise = audioRef.current.play();
      if (audioPromise !== undefined) {
        audioPromise.then(() => {
            audioRef.current.pause();
          })
          .catch((error:any) => {
            audioRef.current.play()
          });
      }
    }
  };

  return (
    <div style={{paddingTop:80}}>
      <div style={{display:'flex', justifyContent:'center'}}>
        <a href="https://www.instagram.com/cerosquema?igsh=dWg0anZibThxY3Fz" target="_blank"><img src={ (window.location.hostname === "localhost") ? require('../images/logo_cero_squema.jpg') : Global.URL + 'images/logo_cero_squema.jpg'} alt="Logo" height={'100'}/></a>
      </div>
      <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
        <Country
          onChange={onChangeCountry}
        />
      </Suspense>

      { currentStation?.code > 0 &&
        <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
          <Station currentStation={currentStation} />
        </Suspense>
      }

      { currentStation?.code > 0  &&
        <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
          <Player
            currentStation={currentStation}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            isMuted={isMuted}
            setIsMuted={setIsMuted}
            stationInfo={stationInfo}
            setStationInfo={setStationInfo}
            audioRef={audioRef}
            stations={stations}
            setCurrentStation={setCurrentStation}
            activeLibraryHandler={activeLibraryHandler}
          />
        </Suspense>
      }
      {(props.country?.code > 0) &&
          <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
            <Library
            country={props.country}
            stations={[]}
            setStations={setStations}
            setCurrentStation={setCurrentStation}
            audioRef={audioRef}
            isPlaying={isPlaying}
            setLibraryStatus={props.setLibraryStatus}
            libraryStatus={props.libraryStatus}
           />
          </Suspense>
      }
      {(props.country?.code > 0) &&
        <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
          <TopStation
            country={props.country}
            setStations={setStations}
            setCurrentStation={setCurrentStation}
            audioRef={audioRef}
            isPlaying={isPlaying}
            setLibraryStatus={props.setLibraryStatus}
          />
        </Suspense>
      }
      <Typography variant="body1" style={{textAlign:'center'}} gutterBottom>
      Dentro de la sección 'Buscar Emisoras', introduce el término de búsqueda en el campo correspondiente y selecciona un resultado para iniciar la reproducción de la estación.
      </Typography>
      {(!currentStation.url?.includes('m3u8')) &&
        <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
          <audio preload="auto"
            onTimeUpdate={timeUpdateHandler}
            onLoadedMetadata={timeUpdateHandler}
            onEnded={stationEndHandler}
            ref={audioRef}
            src={currentStation.url}
          ></audio>
        </Suspense>
      }

      {(currentStation.url?.includes('m3u8')) &&
        <Suspense fallback={<div style={{display:'flex', justifyContent:'center'}}><MoonLoader color='black'/></div>}>
          <ReactHlsPlayer
            playerRef={audioRef}
            src={currentStation.url}
            onTimeUpdate={timeUpdateHandler}
            onLoadedMetadata={timeUpdateHandler}
            width="0"
            height="0"
          />
        </Suspense>
      }
    </div>
  );
  }

  export default Page;
