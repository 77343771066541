import React from "react";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Helmet } from "react-helmet";

const Include = () => {

  return (
    <div style={{paddingTop:80, display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>RadioLibreOnline | Incluir tu radio</title>
        </Helmet>
        <Container component="main" style={{marginTop:8, marginBottom:2}} >
            <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            Incluir tu estación de radio
            </Typography>
            <div style={{textAlign: 'justify'}}>
            Si deseas agregar una estación de radio, por favor envíanos un correo a contacto@radiolibreonline.com con la siguiente información:
                <ul>
                  <li>Nombre de la radio</li>
                  <li>Link de streaming</li>
                  <li>Url sitio web</li>
                  <li>Url logo</li>
                  <li>Ubicación geográfica (País, estado, ciudad)</li>
                  <li>Frecuencia</li>
                  <li>Tags (Etiquetas que describen el contenido de tu radio)</li>
                </ul>
            </div>
        </Container>
    </div>
  );
};

export default Include;