import { Avatar, Box, Button,  Container, CssBaseline,  Grid, Link, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();

    const validateLogin = () => {
        navigate("/menu-admin");
    }

    return (
        <div style={{display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography component="h1" variant="h5">
                    Ingreso al Sistema
                </Typography>
                <Avatar sx={{ m: 1,  }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo Electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        style={{background:'#424242'}}
                        onClick={() => validateLogin()}
                    >
                        Iniciar Sesión
                    </Button>
                    <Grid container>
                        <Grid item xs  style={{textAlign:'center'}}>
                            <Link href="#" variant="body2" fontSize={18}>
                                ¿Olvidó su nombre de usuario o contraseña?
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
        </div>
    );
}

export default Login;