import { Container, Typography } from '@mui/material';
import { useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactHlsPlayer from 'react-hls-player';
import { useLocation } from 'react-router-dom';

const Reproductor = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const src = searchParams.get('src');

    const audioRef = useRef<any>(null);

    const handlePlay = () => {
        console.log('Reproducción iniciada');
    };

    return (
        <HelmetProvider>
        <div style={{paddingTop:80, display: 'flex',flexDirection: 'column',minHeight: '55vh'}}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>RadioLibreOnline | Reproducir Emisora</title>
            </Helmet>
            <Container component="main" style={{marginTop:8,marginBottom:2}}>
                <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                Reproducir Emisora
                </Typography>
                <div style={{textAlign: 'center'}}>
                {(!src?.includes('m3u8')) &&
                    <audio
                        src={src || ''}
                        onPlay={handlePlay}
                        controls
                        autoPlay={false}
                    />
                }
                {(src?.includes('m3u8')) &&
                    <ReactHlsPlayer src={src || ''}
                        playerRef={audioRef}
                        onPlay={handlePlay}
                        autoPlay={false}
                        controls= {true}
                    />
                }
                </div>
            </Container>
        </div>
        </HelmetProvider>
    );
}

export default Reproductor;
